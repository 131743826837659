<i18n>
ru:
  error: К сожалению, этот заказ нельзя повторить ни в одном из городов
  titleRepeat: 'Этот заказ можно повторить только в городах:'
  titleDetails: 'Содержимое этого заказа можно посмотреть только в городах:'
  city: Город
  ok: Продолжить
ua:
  error: На жаль, це замовлення не можна повторити в жодному місті
  titleRepeat: 'Це замовлення можна повторити лише у містах:'
  titleDetails: 'Вміст цього замовлення можна переглянути тільки в містах:'
  city: Місто
  ok: Продовжити
us:
  error: Unfortunately, this order cannot be repeated in any of the cities
  titleRepeat: 'This order can only be repeated in the following cities:'
  titleDetails: 'The contents of this order can only be viewed in the following cities:'
  city: City
  ok: Continue
</i18n>

<template>
  <div class="v-popup-order-repeat-redirect-popup__head v-font-weight-600">
    <span v-html="translate(titleKey)" />
  </div>

  <div class="v-popup-order-repeat-redirect-popup__body v-d-flex v-flex-column">
    <span
      v-if="restaurantData.length === 0"
      v-html="translate('orderRepeatRedirectPopup.error')"
    />
    <arora-radio-input
      v-else-if="restaurantData.length <= 3"
      as-column
      hide-label
      :options="restaurantData.map((item) => item.ID)"
      label=""
      v-model:selected="selectedRestaurantValue"
    >
      <template #option="option">
        <span
          v-html="
            translate('addressDelivery.cityShort', {
              city: restaurantData.find((item) => item.ID === option.value)?.Name ?? ''
            })
          "
        />
      </template>
    </arora-radio-input>
    <arora-select
      v-else
      :label="translate('orderRepeatRedirectPopup.city')"
      :options="restaurantData.map((item) => item.ID)"
      v-model:selected="selectedRestaurant"
    >
      <template #index="options: { index: number }">
        <option
          :value="restaurantData[options.index].ID"
          v-html="translate('addressDelivery.cityShort', { city: restaurantData[options.index].Name })"
        />
      </template>
    </arora-select>
    <arora-button
      class="v-popup-order-repeat-redirect-popup__button"
      :label="translate('orderRepeatRedirectPopup.ok')"
      @click="async () => await continueRepeatRedirect()"
    />
  </div>
</template>

<script setup lang="ts">
import { Guid } from '~api/consts'

import type { Restaurant } from '~types/addressStore'
import type { OrderInfo } from '~types/clientStore'

import type { GUID } from '@arora/common'

const { order, titleKey } = defineProps<{
  order: OrderInfo
  titleKey: string
}>()

const { translate } = useI18nSanitized()
const addressStore = useAddressStore()
const popupStore = usePopupStore()
const { redirectToRestaurant } = useAddressSelector()

onMounted(async () => {
  await addressStore.initRestaurant()
})
const restaurantData = computed<Restaurant[]>(() => {
  const restaurants: Restaurant[] = addressStore.Restaurants.data ?? []

  let total = [] as Restaurant[]

  if (restaurants) {
    total = [
      ...total,
      ...Object.values(restaurants).filter((rest) => {
        return rest.Active && rest.ShowRestaurantInSelect
      })
    ]
  }

  return total
    .filter((item) => order.AvailableRestaurantsIds.includes(item.ID))
    .sort((a, b) => {
      return a.SortWeight === undefined || a.SortWeight > b.SortWeight ? 1 : -1 //sort as in the back
    })
})
const selectedRestaurantValue = ref<GUID>(
  restaurantData.value.find((item) => item.ID === order.RestaurantID)?.ID ?? restaurantData.value[0].ID
)
const selectedRestaurant = computed<GUID>({
  get() {
    return selectedRestaurantValue.value ?? Guid.Empty
  },
  set(newValue: GUID) {
    selectedRestaurantValue.value = newValue
  }
})

async function continueRepeatRedirect(): Promise<void> {
  await popupStore.closePopup()
  if (restaurantData.value.length === 0) {
    return
  }
  const rest = restaurantData.value.find((item) => item.ID === selectedRestaurant.value)
  const getParameters = new Map([['orderRepeatId', order.ID]])
  redirectToRestaurant(rest!, getParameters)
}
</script>

<style lang="scss">
.v-popup-order-repeat-redirect-popup {
  &__head {
    margin-bottom: 0.9rem;
    font-size: 1.4rem;
  }
  &__body {
    gap: 2.25rem;
  }
  &__button {
    align-self: flex-end;
  }
}
</style>
